import { XIcon } from '@heroicons/react/outline';

const Acknowledgment  = () => {

    return(
        <div className="relative bg-spark-yellow">
            <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
              <div className="pr-16 sm:text-center sm:px-16">
                <p className="font-medium text-white text-sm">
                  <span className="md:hidden">SPARK acknowledges that it operates on the traditional, unceded territories of the Semiahmoo, Katzie, Kwikwetlem, Kwantlen, Qayqayt and Tsawwassen First Nations peoples.</span>
                  <span className="hidden md:inline">SPARK acknowledges that it operates on the traditional, unceded territories of the Semiahmoo, Katzie, Kwikwetlem, Kwantlen, Qayqayt and Tsawwassen First Nations peoples.</span>
                </p>
              </div>
             {/* <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
                <button type="button" className="flex p-2 rounded-md hover:bg-spark-yellow focus:outline-none focus:ring-2 focus:ring-white" >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
                </div> */}
            </div>
        </div>
    )
}

export default Acknowledgment ;