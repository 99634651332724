import { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router'
import { Transition } from '@headlessui/react';

const Nav = () => {

  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [toggleMobileNav, setToggleMobileNav] = useState(false);

    return(
        <div className="bg-white">
           

          <div className="bg-white relative pt-7 sm:pt-7 md:pt-10 pb-10 z-20 shadow-md">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <nav className="relative flex items-center justify-end sm:h-10 lg:justify-center" aria-label="Global">
              
              <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link href="/">
                    <a>
                      <img className="h-32 w-auto" src="images/spark-foundation-logo.png" alt="Spark Foundation Logo"/>
                    </a>
                  </Link>

                  {/* MOBILE MENU BUTTON */}
                  <div className="-mr-2 flex items-center md:hidden">
                    <button type="button" onClick={() => setToggleMobileNav(true)} className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" id="main-menu" aria-haspopup="true">
                      <span className="sr-only">Open main menu</span>
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div className="hidden md:flex md:space-x-7">
                  <Link href="/">
                    <a className="font-medium text-gray-900 hover:text-spark-yellow transition duration-100 ease-in-out tracking-wide">HOME</a>
                  </Link>

                  <Link href="/about-us">
                    <a className="font-medium text-gray-900 hover:text-spark-yellow transition duration-100 ease-in-out tracking-wide">ABOUT US</a>
                  </Link>

                  <Link href="/our-programs">
                    <a className="font-medium text-gray-900 hover:text-spark-yellow transition duration-100 ease-in-out tracking-wide">PROGRAMS</a>
                  </Link>
                

                  <Link href="/contact-us">
                      <a className="font-medium text-gray-900 hover:text-spark-yellow hover:text-rdp-orange transition duration-100 ease-in-out tracking-wide">CONTACT</a>
                  </Link>

                  <Link href="/volunteer">
                      <a className="font-medium text-gray-900 hover:text-spark-yellow hover:text-rdp-orange transition duration-100 ease-in-out tracking-wide">VOLUNTEER</a>
                  </Link>
              </div>

              {/* <div className="hidden lg:absolute lg:flex lg:items-center lg:justify-end lg:inset-y-0 lg:right-0">
                <Link href="/volunteer-sign-in">
                    <a className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-spark-yellow hover:bg-black">
                        Volunteer Portal Sign In
                    </a>
                </Link>
              </div> */}
            </nav>
          </div>

        {/*** MOBILE NAV ***/}
        { toggleMobileNav ?
           
            <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                <div className="shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    
                    {/* CLOSE MOBILE NAV BUTTON */}
                      <div className="-mr-2">
                          <button type="button" onClick={() => setToggleMobileNav(false)} className="inline-flex items-center justify-center p-2 rounded-sm text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                          </button>
                      </div>
                            
                    </div>

                  
                  <Transition show={toggleMobileNav} enter="duration-100 ease-out" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95"> 
                      <div role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                        <div className="px-6 pt-2 pb-3 space-y-4" role="none">
                          <Link href="/">
                            <a className="block font-medium text-gray-500 hover:text-rdp-orange transition duration-100 ease-in-out tracking-widest">HOME</a>
                          </Link>

                          <Link href="/about-us">
                            <a className="block font-medium text-gray-500 hover:text-rdp-orange transition duration-100 ease-in-out tracking-widest">ABOUT US</a>
                          </Link>
                          
                          <Link href="/our-programs">
                            <a className="block font-medium text-gray-500 hover:text-rdp-orange transition duration-100 ease-in-out tracking-widest">PROGRAMS</a>
                          </Link>


                          <Link href="/contact-us">
                            <a className="block font-medium text-gray-500 hover:text-rdp-orange transition duration-100 ease-in-out tracking-widest">CONTACT</a>
                          </Link>

                          <Link href="/volunteer">
                            <a className="block font-medium text-gray-500 hover:text-rdp-orange transition duration-100 ease-in-out tracking-widest">VOLUNTEER</a>
                          </Link>
                        </div>
                        {/* <div className="mt-3" role="none">
                         <Link href="/volunteer-sign-in">
                          <a className="block w-full px-5 py-3 text-center font-medium text-gray-500 bg-spark-yellow hover:bg-gray-100" role="menuitem">
                            VOLUNTEER PORTAL SIGN IN
                          </a>
                          </Link>
                        </div> */}
                      </div>
                  </Transition>

                </div>
            </div>

            : "" }
      </div>
    </div>

    )

}

export default Nav;