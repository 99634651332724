import Head from 'next/head';
import Link from 'next/link';
import { useState } from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Acknowledgment from '../components/Acknowledgment';


export default function Home() {

  const [timelineIndex, setTimelineIndex] = useState(0);

  const timelineContent = [
    {date:"March 2014", title:"First Mega Series", description:"Founding and First Mega Series camp with 10 grade 10 students, at Camp Hazlmere.", image:""},
    {date:"Spring 2015", title:"Second Mega Series", description:"Second Mega Series held at Camp Hazelmere with 15 high school students.", image:""},
    {date:"Spring 2016", title:"Third Mega Series", description:"Third Mega Series held at Camp Alexandra house with 20 high school students. We also on boarded 2 more full time volunteers.", image:""},
    {date:"Spring 2017", title:"Fourth Mega Series", description:"Fourth Mega Series. Camp Alexandra. Onboarded another full time volunteer.", image:""},
    {date:"December 2018", title:"First Empower Ball", description:"First Empower Ball Fundraiser on International Day for Human Rights.", image:""},
    {date:"Spring 2019", title:"Fifth Mega Series", description:"Fifth Mega Series at Camp Alexandra Neighbourhood house. SPARK got accepted into SFU Venture connections incubator! Free office space, mentorship and support.", image:""},
    {date:"Summer 2019", title:"UN SDG's", description:"Spoke on SDG 4, Quality Education at the UN. Met with international partners in Washington DC and Australia. Went to Nova Scotia, did a national pitch, and met countess of Wessex and governor of Toronto.", image:""},
    {date:"Fall 2019", title:"Mini Series Launch", description:"Mini Series Launch. Fifth Anniversary. Partnered with Surrey School District (SD 36).", image:""},
    {date:"November 2019", title:"Won Duke Den", description:"Won Duke Den in Toronto - $10,000 duke of Edinburgh International award.", image:""},
    {date:"December 2019", title:"Second Empower Ball", description:"Second Empower Ball. Climate change challenge pitch competition. And Rebranded to SPARK Foundation. Got Governor General sovereignty award for service. Formed a new board.", image:""},
    {date:"Summer 2020", title:"Made Care Packages", description:"Made care packages, 200 meals for frontline workers, made masks. Onboarded six new members. On boarded 6 full time summer staff through Canada Summer Jobs.", image:""},
    {date:"Fall 2020", title:"First High School Careers Conference", description:"First high school careers conference (online!).", image:""},
    {date:"Winter 2020", title:"Assembled 150 Backpacks", description:"Assembled 150 backpacks filled of school supplies for low-income families, received $20,000 for this project Canada Red Cross.", image:""},
    {date:"Spring 2021", title:"Made 800 Care Packages", description:"Made 800 care packages for low income families and the homeless population. And donating 6 3d printers to SD 36, 50 free mini series workshops to students in the Metro Vancouver area, 60 mental health crafts kits thanks to the $63,000 received from Canada Red Cross for this project.", image:""},
    {date:"Fall 2021", title:"Collaborating with the Government of Canada", description:"Collaborating with the Government of Canada and local Non-Profit Organizations, we have organized both in-person and online workshops focused on technology. These workshops have ignited transformation in the lives of 800 youth across Canada", },
    {date:"Winter 2021", title:"Teaming up with three youth organizations", description: `Teaming up with three youth organizations, including the Global Communications Centre at the United Nations, we co-hosted a panel discussion titled "The Power of Volunteering." This event left a lasting impact on 150 young individuals.`},
    {date:"Spring 2022", title:"Organized a panel discussion", description: `Organized a panel discussion titled "Let's Talk University" featuring three students from SFU, UBC, and UVIC. This event positively influenced the lives of 100 youth participants.`},
    {date:"Summer 2022 ", title:"We partnered to donate 500 books", description: "We partnered to donate 500 books for elementary school children, which were given to Wrote to Read to provide literacy materials for Indigenous communities in rural areas."},
    {date:"Fall 2022", title:"Hosted an online workshop", description: `We hosted an online workshop centred on "All About University Applications," empowering 100 students.`},
    {date:"Winter 2022", title:"Organized a workshop and pitch competition", description: "We organized a workshop and pitch competition focusing on solutions for access to quality education. Youth participants learned valuable skills including problem-solving, public speaking, time management, and strategic thinking. A total of $600 was awarded to the top three teams, along with mentorship and an opportunity to present to the school trustees."},
    {date:"Summer 2023 ", title:"Named the “Most Empowering Life Education Programming in Canada”", description: "SPARK Foundation has been named as “Most Empowering Life Education Programming in Canada” by Acquisition International Magazine!"},
    {date:"Fall 2023", title:"SPARK Foundation proudly supports Princess Margaret School's Low Earth Orbit team", description: "SPARK Foundation proudly supports Princess Margaret School's Low Earth Orbit team with a $4000 donation for their upcoming national NASA competition. This talented group will represent Canada in the NASA - student led competition next year, taking place in Alabama! "},
    {date:"Spring 2024", title:"Hosted an Educational Seminar", description: "SPARK Foundation, in collaboration with Youth Talks and Higher Education for Good Foundation, hosted an educational seminar aimed at helping individuals discover their passions and make a positive impact in their communities. Participants also learned about utilizing AI for benevolent purposes."},
    {date:"Summer 2024", title:"Chosen by TheirWorld and the United Nations to participate in the Spring Meetings in Washington DC.", description: "SPARK Foundation has been chosen by TheirWorld and the United Nations to participate in the Spring Meetings in Washington DC. Our founders will advocate for increased funding for education systems, teacher support, and the creation of inclusive classrooms."},
    {date:"Fall 2024", title:"Transformation", description: "SPARK Foundation has transformed into an advocacy organization championing education rights and youth engagement. Concurrently, we provide educational opportunities for youth to thrive and succeed in life."}
  ];

    const handleNextTimeline = () => {
      if (timelineIndex === timelineContent.length-1){
          return;
      }
      else{
          setTimelineIndex(timelineIndex + 1);
      }
  }

  const handlePreviousTimeline = () => {
      if (timelineIndex === 0){
          return;
      }
      else{
          setTimelineIndex(timelineIndex - 1);
      }
  }

  return (
    <div>
     <Head>
        <title>SPARK Foundation - Home</title>
        <link rel="icon" href="/favicon-32x32.png" />
        <meta property="og:title" content="SPARK Foundation - Home" key="title" />
        <meta name="description" content="" />
      </Head>

      <div className="h-screen flex flex-col z-10">
      <Nav/>
        <main className="mx-auto my-auto max-w-7xl px-4 z-10">
          <div className="object-cover bg-gray-300 bg-opacity-50 top-0 left-0 w-screen h-screen absolute"> 
            <video autoPlay={true} muted loop playsInline className="object-cover top-0 left-0 w-screen h-screen absolute -z-10" src="/videos/spark-home-vid.mp4" poster="/images/main-bg-vid-image2.jpg"></video>
          </div>
          <div className="text-center -mt-16 relative z-10">
            <h1 className="text-6xl text-spark-yellow tracking-wider sm:text-6xl md:text-6xl filter drop-shadow-lg">
              <span className="block font-light">Welcome To </span>
              <span className="block font-extrabold"> The SPARK Foundation </span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-black sm:text-lg md:mt-5 md:text-2xl md:max-w-3xl">
            Empowering youth voices, while creating change one SPARK at a time
            </p>
           
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <Link href="/contact-us">
                <a rel="noreferrer noopener" className="w-full flex items-center justify-center px-12 py-3 text-base font-medium tracking-widest rounded-md transition duration-500 ease-in-out text-white bg-black hover:bg-spark-yellow hover:text-white md:py-3 md:text-base md:px-10">
                  GET IN TOUCH
                </a>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* LOGO SECTION */}

      <section>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-3 gap-8 md:grid-cols-6 lg:grid-cols-6">
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-24" src="images/logo-bbc-world-news-grey.png" alt="BBC World News Logo"/>
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-24" src="images/logo-globe-and-mail-grey.png" alt="Globe & Mail Logo"/>
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-24" src="images/logo-global-bc-grey.png" alt="Global BC Logo"/>
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-24" src="images/logo-surrey-now-leader.jpeg" alt="Surrey Now Leader Logo"/>
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-24" src="images/logo-darpan-magazine.jpg" alt="Darpan Magazine Logo"/>
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-24" src="images/logo-drishti-magazine.jpg" alt="Drishti Magazine Logo"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Acknowledgment/>

       {/* Mission Statement*/}

      <section>
        <div className="relative bg-gray-50 pt-12 pb-12 overflow-hidden">
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-12">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Mission Statement
                    </h2>
                    <p className="mt-4 text-xl text-gray-500">
                    SPARK Foundation is dedicated to empowering the next generation through free life education programming, fostering personal growth, and breaking down barriers to access higher education. We strive to ignite a passion for lifelong learning, equipping individuals with the knowledge, skills, and opportunities needed to thrive in an ever-evolving world.
                    </p>

                    <h2 className="mt-10 text-3xl font-extrabold tracking-tight text-gray-900">
                      Vision
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                    Our vision is to empower youth to drive meaningful change aligned with the UN SDGs, creating a sustainable world by 2030.
                    </p>

                    <h2 className="mt-10 text-3xl font-extrabold tracking-tight text-gray-900">
                      Values
                    </h2>
                    <ul className="mt-4 space-y-3 text-lg text-gray-500">
                     <li>S: We prioritize sustainability in our programs, materials, and methods of operation.</li>
                     <li>P: We demonstrate philanthropy through our charitable contributions and community events.</li>
                     <li>A: We embrace adaptability in unfamiliar settings, striving to generate innovative solutions.</li>
                     <li>R: We honour our elders, cherish the land we inhabit, and show gratitude to those who nurture our community.</li>
                     <li>K: Our guiding principle is kindness, extending compassion to everyone above all else.</li>
                    </ul>
                 
                  </div>
                </div>
            
              </div>

              <div className="mt-12 sm:mt-16 lg:mt-24">
                <div className="pl-0 lg:pl-4">
                  <img className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5" src="images/mission.jpg" alt="SPARK Foundation Team Members in a group photo"/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

       {/* UN SDG's */}

      <section>
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3"></div>
          </div>
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              United Nations Sustainable Development Goals
              </h2>
              <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              At SPARK we are working towards creating a sustainable world along side the UN through the following Sustainable Development Goals
              </p>
            </div>

            <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img className="h-full w-full object-cover" src="images/E-WEB-Goal-04.jpeg" alt=""/>
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                   
                      <p className="mt-3 text-base text-gray-500 text-center">
                      Through our workshops and camps we aim to provide quality life education. 
                      </p>
               
                  </div>
                  <div className="mt-6 flex items-center">
                    <a href="https://sdgs.un.org/goals/goal4" target="_blank" rel="noopener noreferrer" className="inline-flex w-full justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-spark-yellow hover:bg-black">
                        Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img className="h-full w-full object-cover" src="images/E-WEB-Goal-11.jpeg" alt=""/>
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                      
                      <p className="mt-3 text-base text-gray-500 text-center">
                      Through our participants action plans and policy work.
                      </p>
              
                  </div>
                  <div className="mt-6 flex items-center">
                    <a href="https://sdgs.un.org/goals/goal11" target="_blank" rel="noopener noreferrer" className="inline-flex w-full justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-spark-yellow hover:bg-black">
                        Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img className="h-full w-full object-cover" src="images/E-WEB-Goal-13.jpeg" alt=""/>
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
   
                      <p className="mt-3 text-base text-gray-500 text-center">
                      Through our climate change challenge and sustainable local sourcing for all of our in house materials. 
                      </p>
             
                  </div>
                  <div className="mt-6 flex items-center">
                    <a href="https://sdgs.un.org/goals/goal13" target="_blank" rel="noopener noreferrer" className="inline-flex w-full justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-spark-yellow hover:bg-black">
                        Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img className="h-full w-full object-cover" src="images/E-WEB-Goal-17.jpeg" alt=""/>
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">

                      <p className="mt-3 text-base text-gray-500 text-center">
                      Through partnerships and cross collaboration. 
                      </p>
               
                  </div>
                  <div className="mt-6 flex items-center">
                    <a href="https://sdgs.un.org/goals/goal17" target="_blank" rel="noopener noreferrer" className="inline-flex w-full justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-spark-yellow hover:bg-black">
                        Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* TESTIMONIALS */}

      <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

          <div className="relative">
          
            <blockquote className="mt-10">
              <div className="max-w-3xl mx-auto text-center text-3xl leading-9 font-medium text-gray-900">
                <p>
                  &ldquo;Never doubt that a small group of thoughtful, committed, citizens can change the world. Indeed, it is the only thing that ever has.&rdquo;
                </p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                 
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-gray-900">Margaret Mead</div>

                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>

       {/* TIMELINE */}

      <section>
        <div className="bg-black py-16 lg:py-24">
      
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

            <h2 className="text-5xl text-center tracking-tight font-light text-white sm:text-5xl mb-12">
              The <span className="text-spark-yellow font-extrabold">SPARK</span> Timeline
            </h2>

            <div className="text-center relative h-full py-24 px-8 bg-spark-yellow rounded-xl shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-2 lg:gap-x-8">
              <div className="absolute inset-0 opacity-40 filter saturate-0 mix-blend-multiply">
                <img src="images/timeline-image.jpg" alt="" className="w-full h-full object-cover" />
              </div>
              <div className="relative lg:col-span-2">
              
                <blockquote className="mt-6 text-white">
                  <p className="flex flex-col font-light uppercase text-xl mb-1">
                      <span>{timelineContent[timelineIndex].date}</span>
                  </p>
                  <p className="flex flex-col capitalize font-bold tracking-wide text-3xl mb-10">
                      <span>{timelineContent[timelineIndex].title}</span>
                  </p>
                  <p className="text-2xl font-light sm:text-4xl h-48 lg:h-32">
                    {timelineContent[timelineIndex].description}
                  </p>
                
                </blockquote>

                <div className="flex justify-center mt-24 space-x-3">
                    <button className="px-3 py-3 bg-black rounded-md" onClick={handlePreviousTimeline} aria-label="Previous Timeline Event">
                        <svg className="w-8 h-8 text-white hover:text-spark-yellow" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                        </svg>
                    </button>
                    <button className="px-3 py-3 bg-black rounded-md" onClick={handleNextTimeline} aria-label="Next Timeline Event">
                        <svg className="w-8 h-8 text-white hover:text-spark-yellow" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                        </svg>
                    </button>
                </div>
         
              
              </div>
            </div>
          </div>
      </div>
      </section>

      <Footer/>
    </div>
  )
}
